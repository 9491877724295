<template>
  <div
    ref="table-container"
    class="ant-glass-background radius-0 flex-1 flex-scroll-height overflow-y-auto"
  >
    <dynamic-data-table
      ref="tasks-data-table"
      table-title=""
      :table-records="tasks"
      :table-headers="taskTableHeaders"
      :clickable-rows="true"
      :has-top="false"
      :can-select="true"
      :table-height="`${tableHeight - 59}px`"
      :is-loading="tasksStatus === 'loading'"
      @clickedRow="openTaskDetail"
      @selectedRecords="selectedRecords"
    >
      <template #item.status="{ value, rowId, item }">
        <v-icon v-if="value === 'open'">
          mdi-checkbox-blank-circle-outline
        </v-icon>
        <v-icon v-else-if="value === 'canceled'">
          mdi-alert-rhombus-outline
        </v-icon>
        <v-icon v-else-if="value === 'closed'"
          >mdi-checkbox-marked-circle-outline
        </v-icon>
      </template>
      <template #item.priority="{ value, rowId, item }">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon :color="getPriorityColor(value)" dense v-on="on"
              >{{ getPriorityIcon(value) }}
            </v-icon>
          </template>
          <span>{{ value }}</span>
        </v-tooltip>
      </template>
      <template #item.task_project="{ value, rowId, item }">
        {{ value ? value.name : '-' }}
      </template>
      <template #item.due="{ value, rowId, item }">
        {{ formatDate(value) }}
      </template>
      <template #item.labels="{ value, rowId, item }">
        <v-chip
          v-for="label in item.labels"
          :key="label.id"
          label
          small
          class="mr-1"
          :color="label.color"
          :text-color="getTextContrastColor(label.color)"
          >{{ label.title }}</v-chip
        >
      </template>

      <template #item.assigned_to="{ value, rowId, item }">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <div v-on="on">
              <v-avatar color="primary" size="25" class="mr-2 white--text">
                {{ `${value.firstname.charAt(0)}${value.lastname.charAt(0)}` }}
              </v-avatar>
              {{ `${value.firstname} ${value.lastname}` }}
            </div>
          </template>
          <span>{{ value.email }}</span>
        </v-tooltip>
      </template>
    </dynamic-data-table>
  </div>
</template>

<script>
import DynamicDataTable from '../../DynamicDataTable';
import TaskHelper from '../../../services/task-helper';
import { mapGetters } from 'vuex';
import moment from 'moment';
import ColorHelper from '@/services/color-helper';
export default {
  name: 'TaskListView',
  components: { DynamicDataTable },
  data: () => {
    return {
      taskTableHeaders: [
        {
          text: 'status',
          value: 'status',
          hasSlot: true,
        },
        {
          text: '#',
          value: 'number',
        },
        {
          text: 'priority',
          value: 'priority',
          hasSlot: true,
        },
        {
          text: 'title',
          value: 'title',
        },
        {
          text: 'description',
          value: 'description',
        },
        {
          text: 'project',
          value: 'task_project',
          hasSlot: true,
        },
        {
          text: 'due',
          value: 'due',
          hasSlot: true,
        },
        {
          text: 'labels',
          value: 'labels',
          hasSlot: true,
        },
        {
          text: 'assigned to',
          value: 'assigned_to',
          hasSlot: true,
        },
      ],
      tableHeight: 0,
      resizeEvent: null,
    };
  },
  computed: {
    ...mapGetters(['tasks', 'tasksStatus', 'selectedTasks']),
  },
  watch: {
    selectedTasks: {
      handler() {
        this.$refs['tasks-data-table'].tableModel = this.selectedTasks;
      },
    },
    tasks: {
      handler() {
        this.$refs['tasks-data-table'].tableModel = [];
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 52 - 45;
      this.$el.addEventListener('resize', (evt) => {
        this.handleResizeEvent(evt);
      });
    });
  },
  destroyed() {
    this.$el.removeEventListener('resize', (evt) => {
      this.handleResizeEvent(evt);
    });
  },
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    getTextContrastColor(color) {
      return ColorHelper.getBlackOrWhiteBasedOnBackground(color);
    },
    openTaskDetail(task) {
      this.$router.push({ name: 'tasks-detail', params: { taskId: task.id } });
    },
    getPriorityIcon(priority) {
      return TaskHelper.getPriorityIcon(priority);
    },
    getPriorityColor(priority) {
      return TaskHelper.getPriorityColor(priority);
    },
    handleResizeEvent(evt) {
      this.tableHeight = this.$refs['table-container'].offsetHeight;
    },
    selectedRecords(records) {
      this.$store.commit('selected_tasks_success', records);
    },
  },
};
</script>

<style scoped></style>
