<template>
  <div
    class="flex-1 px-5 pb-5 flex-scroll-width overflow-y-hidden d-flex flex-column"
  >
    <div
      class="d-flex mb-2 py-2 px-5 ant-glass-background radius-0 align-center ant-border"
    >
      <v-select
        filled
        item-text="title"
        item-value="id"
        :value="board"
        dense
        hide-details
        placeholder="Select board"
        :items="boards"
        class="radius-0"
        return-object
        style="flex: 1"
        @change="selectBoard"
      />
      <v-tooltip v-if="!addBoardToggle" right>
        <template #activator="{ on, attrs }">
          <v-btn
            :ripple="false"
            tile
            icon
            style="height: 40px; width: 40px"
            v-bind="attrs"
            v-on="on"
            @click="addBoardToggle = true"
          >
            <v-icon style="font-size: 20px">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Create board</span>
      </v-tooltip>
      <v-text-field
        v-if="addBoardToggle"
        v-model="boardTitle"
        class="ml-2 radius-0"
        hide-details
        dense
        filled
        clearable
        autofocus
        single-line
        placeholder="title"
        @click="
          addBoardToggle = false;
          boardTitle = null;
        "
        @keydown.enter="createBoard()"
      />

      <v-spacer style="flex: 4 4 auto" />
      <div style="flex: 1 1 auto" class="d-flex justify-end align-center">
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-btn
              :ripple="false"
              tile
              icon
              style="height: 40px; width: 40px"
              v-bind="attrs"
              :disabled="board ? !!board.default : false"
              v-on="on"
              @click="makeBoardDefault"
            >
              <v-icon style="font-size: 20px">mdi-select-all</v-icon>
            </v-btn>
          </template>
          <span>Make board default</span>
        </v-tooltip>
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-btn
              :ripple="false"
              tile
              icon
              style="height: 40px; width: 40px"
              v-bind="attrs"
              v-on="on"
              @click="boardDeleteDialog = true"
            >
              <v-icon style="font-size: 20px">mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Delete board</span>
        </v-tooltip>
        <v-menu :offset-y="true">
          <template #activator="{ on, attrs }">
            <v-btn
              tile
              outlined
              color="gainsboro"
              small
              class="ml-2"
              v-bind="attrs"
              v-on="on"
              >create list
            </v-btn>
          </template>
          <div class="d-flex flex-column ant-glass-background pa-2">
            <v-chip
              v-for="(label, index) in unSelectedBoardLabels"
              :key="label.id"
              small
              :class="{ 'mb-1': index !== unSelectedBoardLabels.length - 1 }"
              :text-color="getTextContrastColor(label.color)"
              :color="label.color"
              @click="addLaneToBoard(label)"
            >
              {{ label.title }}
            </v-chip>
          </div>
        </v-menu>
        <delete-dialog
          :dialog="boardDeleteDialog"
          :title="`Are you sure you want to delete ${
            board ? board.title : ''
          }?`"
          @closeDialog="boardDeleteDialog = false"
          @deleteAction="deleteBoard"
        />
      </div>
    </div>
    <div class="overflow-x-auto flex-1 flex-scroll-width">
      <div
        v-if="boardLanes.length > 0"
        class="d-inline-flex full-height flex-1 overflow-x-auto"
        style="min-width: min-content"
      >
        <div
          v-for="(lane, laneIndex) in boardLanes"
          :key="lane.id"
          class="board-list-container mr-1 d-flex flex-column pa-1 ant-border ant-glass-background radius-0"
          :class="{ collapsed: lane.collapsed }"
        >
          <div v-if="lane.collapsed" class="align-center d-flex flex-column">
            <v-icon
              small
              class="mb-2 mt-1"
              @click="lane.collapsed = !lane.collapsed"
            >
              mdi-chevron-right
            </v-icon>
            <div
              v-if="lane.type === 'status'"
              style="writing-mode: vertical-lr; font-size: 14px"
              class="mb-2 py-2 px-1 font-weight-bold"
            >
              {{ lane.label }}
            </div>
            <div
              v-else
              style="
                font-size: 12px;
                writing-mode: vertical-lr;
                border-radius: 12px;
              "
              class="white--text mb-2 py-2 px-0"
              :style="{ 'background-color': lane.color }"
            >
              {{ lane.label }}
            </div>
            <v-icon small class="mr-1"> mdi-clipboard-multiple-outline</v-icon>
            <div style="font-size: 11px">
              {{ lane.items.length }}
            </div>
          </div>
          <div v-if="!lane.collapsed" class="align-center d-flex">
            <v-icon
              class="mr-2 ml-1"
              small
              @click="lane.collapsed = !lane.collapsed"
            >
              mdi-chevron-down
            </v-icon>
            <div
              v-if="lane.type === 'status'"
              style="font-size: 14px"
              class="font-weight-bold"
            >
              {{ lane.label }}
            </div>
            <v-chip
              v-else
              small
              :text-color="getTextContrastColor(lane.color)"
              :color="lane.color"
            >
              {{ lane.label }}
            </v-chip>
            <v-spacer />
            <v-icon small class="mr-1"> mdi-clipboard-multiple-outline</v-icon>
            <div style="font-size: 11px" class="mr-1">
              {{ lane.items.length }}
            </div>
            <v-menu v-if="lane.type === 'label'" :offset-x="true">
              <template #activator="{ on, attrs }">
                <v-icon v-bind="attrs" class="mr-1" small v-on="on"
                  >mdi-dots-vertical
                </v-icon>
              </template>
              <div class="d-flex flex-column ant-glass-background pa-2">
                <div
                  class="d-flex lane-option-list-item my-1"
                  style="font-size: 12px"
                  @click="deleteLaneFromBoard(lane)"
                >
                  <v-icon x-small class="mr-2"> mdi-delete</v-icon>
                  Delete list
                </div>
                <div
                  v-if="
                    labelLanes.length > 1 && laneIndex < boardLanes.length - 2
                  "
                  class="d-flex lane-option-list-item my-1"
                  style="font-size: 12px"
                  @click="moveLaneToRight(lane)"
                >
                  <v-icon x-small class="mr-2"> mdi-arrow-right</v-icon>
                  Move right
                </div>
                <div
                  v-if="labelLanes.length > 1 && laneIndex > 1"
                  class="d-flex lane-option-list-item my-1"
                  style="font-size: 12px"
                  @click="moveLaneToLeft(lane)"
                >
                  <v-icon x-small class="mr-2"> mdi-arrow-left</v-icon>
                  Move left
                </div>
              </div>
            </v-menu>
          </div>
          <div
            v-if="!lane.collapsed"
            class="overflow-y-auto flex-scroll-height flex-1 py-5 d-flex"
          >
            <draggable
              v-model="lane.items"
              group="task"
              handle=".handle"
              class="flex-1"
              @change="updateTaskStatus($event, lane)"
            >
              <div
                v-for="task in lane.items"
                :key="task.id"
                class="background-white pa-2 my-1 board-task-container handle"
              >
                <div class="d-flex" style="font-size: 12px; font-weight: 500">
                  <div class="d-flex flex-column">
                    <div class="d-flex align-center">
                      <span
                        class="ant-icon"
                        @click.stop="openTaskDetail(task)"
                        >{{ task.title }}</span
                      >
                    </div>
                    <div class="d-flex align-center mt-2">
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-icon
                            :color="getPriorityColor(task.priority)"
                            small
                            class="align-self-start"
                            v-on="on"
                            >{{ getPriorityIcon(task.priority) }}
                          </v-icon>
                        </template>
                        <span>{{ task.priority }}</span>
                      </v-tooltip>
                      <div class="fs-12 ml-1" style="color: gray; font-weight: 400">
                        #{{ task.number }}
                      </div>
                    </div>
                  </div>

                  <v-spacer />
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon
                        dense
                        class="handle"
                        style="cursor: move"
                        v-on="on"
                      >
                        mdi-drag
                      </v-icon>
                    </template>
                    <span>Move task</span>
                  </v-tooltip>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
      <div v-else class="d-flex full-height align-center justify-center">
        <ant-loading />
      </div>
    </div>
  </div>
</template>

<script>
import TaskHelper from '../../../services/task-helper';
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import DeleteDialog from '@/components/DeleteDialog';
import AntLoading from '@/components/AntLoading';
import ColorHelper from '@/services/color-helper';
import { getLabels } from '@/services/api/labels.api';

export default {
  name: 'TasksBoardView',
  components: {
    AntLoading,
    DeleteDialog,
    draggable,
  },
  data: () => {
    return {
      boardColumns: [],
      addBoardToggle: false,
      boardTitle: null,
      boardDeleteDialog: false,
      boardLanes: [],
      labelOptions: [],
      switch: {
        added: null,
        removed: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      'tasks',
      'tasksStatus',
      'boards',
      'boardsStatus',
      'board',
      'selectedLicense',
    ]),

    labelLanes() {
      return this.boardLanes.filter((lane) => lane.type === 'label');
    },

    unSelectedBoardLabels() {
      return this.labelOptions.filter(
        (label) =>
          this.boardLanes
            .filter((lane) => lane.type === 'label')
            .findIndex((lane) => lane.label_id === label.id) === -1
      );
    },
  },
  watch: {
    tasksStatus(value) {
      if (value === 'success') {
        this.setBoardLaneItems();
      }
    },
    board: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.boardLanes = [];
          this.boardLanes.push({
            label: 'open',
            collapsed: false,
            items: [],
            type: 'status',
          });
          value.lanes.forEach((lane) => {
            this.boardLanes.push({
              label: lane.lane_label.title,
              label_id: lane.lane_label.id,
              lane_index: lane.index,
              id: lane.id,
              collapsed: false,
              items: [],
              type: 'label',
              color: lane.lane_label.color,
            });
          });
          this.boardLanes.push({
            label: 'closed',
            collapsed: false,
            items: [],
            type: 'status',
          });

          this.setBoardLaneItems();
        }
      },
    },
  },
  async mounted() {
    await this.$store.dispatch('fetchBoards');
    this.labelOptions = await getLabels({
      'filter[license]': this.selectedLicense.id,
    });
  },
  methods: {
    setBoardLaneItems() {
      this.boardLanes.forEach((lane) => {
        if (lane.type === 'status') {
          lane.items = this.tasks.filter((task) => {
            if (task.status === lane.label) {
              return (
                task.labels.findIndex(
                  (label) =>
                    this.boardLanes.findIndex(
                      (lane) => lane.label_id === label.id
                    ) !== -1
                ) === -1
              );
            }
            return false;
          });
        } else {
          lane.items = this.tasks.filter(
            (task) =>
              task.labels.findIndex((label) => label.id === lane.label_id) !==
                -1 && task.status !== 'closed'
          );
        }
      });
    },
    getTextContrastColor(color) {
      return ColorHelper.getBlackOrWhiteBasedOnBackground(color);
    },
    getPriorityIcon(priority) {
      return TaskHelper.getPriorityIcon(priority);
    },
    getPriorityColor(priority) {
      return TaskHelper.getPriorityColor(priority);
    },
    selectBoard(board) {
      this.boardLanes = [];
      this.$store.dispatch('fetchBoard', board.id);
    },
    moveLaneToLeft(lane) {
      this.$store.dispatch('updateBoardLane', {
        boardId: this.board.id,
        laneId: lane.id,
        body: {
          index: lane.lane_index - 1,
        },
      });
    },
    moveLaneToRight(lane) {
      this.$store.dispatch('updateBoardLane', {
        boardId: this.board.id,
        laneId: lane.id,
        body: {
          index: lane.lane_index + 1,
        },
      });
    },
    async updateTaskStatus(evt, lane) {
      if (evt.hasOwnProperty('added')) {
        this.switch.added = {
          evt: evt,
          lane: lane,
        };
      } else if (evt.hasOwnProperty('removed')) {
        this.switch.removed = {
          evt: evt,
          lane: lane,
        };
      }

      if (this.switch.added && this.switch.removed) {
        if (
          this.switch.added.lane.type === 'label' &&
          this.switch.removed.lane.type === 'label'
        ) {
          // change labels
          await this.$store.dispatch('createTaskLabel', {
            taskId: this.switch.added.evt.added.element.id,
            labelId: this.switch.added.lane.label_id,
          });
          await this.$store.dispatch('deleteTaskLabel', {
            taskId: this.switch.removed.evt.removed.element.id,
            labelId: this.switch.removed.lane.label_id,
          });
        }

        if (
          this.switch.added.lane.type === 'label' &&
          this.switch.removed.lane.type === 'status'
        ) {
          if (
            this.switch.removed.lane.type === 'status' &&
            this.switch.removed.lane.label === 'closed'
          ) {
            await this.$store.dispatch(
              'reopenTask',
              this.switch.removed.evt.removed.element.id
            );
          }
          await this.$store.dispatch('createTaskLabel', {
            taskId: this.switch.added.evt.added.element.id,
            labelId: this.switch.added.lane.label_id,
          });
        }

        if (
          this.switch.added.lane.type === 'status' &&
          this.switch.removed.lane.type === 'label'
        ) {
          await this.$store.dispatch('deleteTaskLabel', {
            taskId: this.switch.removed.evt.removed.element.id,
            labelId: this.switch.removed.lane.label_id,
          });

          if (
            this.switch.added.lane.type === 'status' &&
            this.switch.added.lane.label === 'closed'
          ) {
            await this.$store.dispatch(
              'closeTask',
              this.switch.added.evt.added.element.id
            );
          }
        }

        if (
          this.switch.added.lane.type === 'status' &&
          this.switch.removed.lane.type === 'status'
        ) {
          if (this.switch.added.lane.label === 'open') {
            await this.$store.dispatch(
              'reopenTask',
              this.switch.added.evt.added.element.id
            );
          }
          if (this.switch.added.lane.label === 'closed') {
            await this.$store.dispatch(
              'closeTask',
              this.switch.added.evt.added.element.id
            );
          }
        }

        this.switch = {
          added: null,
          removed: null,
        };
        this.setBoardLaneItems();
      }
    },
    openTaskDetail(task) {
      this.$router.push({ name: 'tasks-detail', params: { taskId: task.id } });
    },

    createBoard() {
      let body = {
        title: this.boardTitle,
      };
      this.$store.dispatch('createBoard', body).then(() => {
        this.boardTitle = null;
        this.addBoardToggle = false;
      });
    },
    deleteBoard() {
      this.$store
        .dispatch('deleteBoard', this.board.id)
        .then(() => (this.boardDeleteDialog = false));
    },
    makeBoardDefault() {
      this.$store.dispatch('updateBoard', {
        boardId: this.board.id,
        body: {
          default: true,
        },
      });
    },
    addLaneToBoard(label) {
      let body = {
        label: label.id,
      };
      this.$store.dispatch('createBoardLane', {
        boardId: this.board.id,
        body,
      });
    },
    deleteLaneFromBoard(lane) {
      this.$store.dispatch('deleteBoardLane', {
        boardId: this.board.id,
        laneId: lane.id,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.board-list-container {
  width: 300px;
  height: 100%;

  &.collapsed {
    width: 40px;
  }

  .board-task-container {
    transition: 200ms;
    cursor: pointer;

    &:hover {
      background-color: #f7f8fa;
    }
  }
}

.lane-option-list-item {
  transition: 200ms;
  cursor: pointer;

  &:hover {
    .v-icon {
      color: var(--v-primary-base);
    }
  }
}

.ant-icon {
  &:hover {
    text-decoration: underline;
  }
}
</style>
