<template>
  <div
    class="flex-1 ant-glass-background radius-0 d-flex flex-column flex-scroll-height"
  >
    <v-sheet tile height="64" class="d-flex bg-transparent">
      <v-toolbar flat class="bg-transparent">
        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
          {{ $t('general.today') }}
        </v-btn>
        <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon class="ma-2" @click="$refs.calendar.next()">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer />
        <v-menu bottom right>
          <template #activator="{ on, attrs }">
            <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
              <span>{{ type }}</span>
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in types"
              :key="index"
              @click="type = item"
            >
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>
    <v-calendar
      ref="calendar"
      v-model="focus"
      class="overflow-y-auto overflow-x-hidden bg-transparent pb-1"
      :weekdays="weekday"
      :type="type"
      color="primary"
      :events="events"
      :event-color="getEventColor"
      @change="getEvents"
      @click:event="openTaskDetail"
    />
  </div>
</template>

<script>
import moment from 'moment/moment';
import { mapGetters } from 'vuex';
import TaskHelper from '../../../services/task-helper';

export default {
  name: 'TasksCalendarView',
  data: () => {
    return {
      type: 'week',
      types: ['month', 'week', 'day'],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      events: [],
      focus: '',
    };
  },
  computed: {
    ...mapGetters(['tasks']),
  },
  mounted() {
    this.$store.dispatch('fetchTasks');
  },
  methods: {
    setToday() {
      this.focus = '';
    },
    getEvents({ start, end }) {
      const events = [];

      let rangeTasks = this.tasks.filter((task) => {
        if (task.planned_start && task.planned_end) {
          return (
            moment(task.planned_start).isBetween(
              moment(start.date),
              moment(end.date),
              null,
              '[]'
            ) ||
            moment(task.planned_end).isBetween(
              moment(start.date),
              moment(end.date),
              null,
              '[]'
            ) ||
            (moment(task.planned_start).isSameOrBefore(start.date, 'days') &&
              moment(task.planned_end).isSameOrAfter(end.date, 'days'))
          );
        } else {
          return false;
        }
      });

      rangeTasks.forEach((task) => {
        events.push({
          id: task.id,
          name: task.name,
          start: moment(task.planned_start).valueOf(),
          end: moment(task.planned_end).valueOf(),
          color: 'grey',
          timed: true,
        });
      });

      this.events = events;
    },
    getEventColor(event) {
      return event.color;
    },

    openTaskDetail({ event }) {
      this.$router.push({ name: 'tasks-detail', params: { taskId: event.id } });
    },
  },
};
</script>

<style scoped></style>
