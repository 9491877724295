import { render, staticRenderFns } from "./TasksProjectsView.vue?vue&type=template&id=03ef95a5&scoped=true"
import script from "./TasksProjectsView.vue?vue&type=script&lang=js"
export * from "./TasksProjectsView.vue?vue&type=script&lang=js"
import style0 from "./TasksProjectsView.vue?vue&type=style&index=0&id=03ef95a5&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03ef95a5",
  null
  
)

export default component.exports