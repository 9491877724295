<template>
  <div
    v-if="tasksTreeStatus === 'success'"
    class="d-flex flex-column flex-1 flex-scroll-width overflow-x-auto"
  >
    <tasks-row-chart
      :tasks="tasksTree"
      :headers="taskProjects"
      :get-header-tasks="getTasksByProject"
    >
      <template #task-header-items>
        <div
          v-for="project in taskProjects"
          :key="project.id"
          class="d-flex align-center px-2 py-1 ant-border-bottom"
          style="height: 36px"
          @click="projectTaskDetail(project)"
        >
          <div class="ant-gantt-project-label">
            {{ project.name }}
          </div>

          <v-spacer />
          <div style="font-size: 12px; color: gray">
            {{ project.number }}
          </div>
        </div>
      </template>
    </tasks-row-chart>
  </div>
  <div v-else class="d-flex align-center justify-center flex-1">
    <ant-loading />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AntLoading from '@/components/AntLoading';
import TasksRowChart from '@/components/Charts/TasksRowChart.vue';

export default {
  name: 'TasksProjectsView',
  components: { TasksRowChart, AntLoading },
  computed: {
    ...mapGetters(['tasksTree', 'tasksTreeStatus', 'selectedLicense']),
    taskProjects() {
      let projects = [];
      this.tasksTree.forEach((task) => {
        if (
          task.task_project &&
          projects.findIndex(
            (project) => project.id === task.task_project.id
          ) === -1
        ) {
          projects.push(task.task_project);
        }
      });
      return projects;
    },
  },
  mounted() {
    this.$store.dispatch('fetchTaskTree', {
      'filter[parent]': '',
      'filter[license]': this.selectedLicense.id,
    });
  },
  methods: {
    getTasksByProject(project) {
      return this.tasksTree.filter(
        (task) => task.task_project.id === project.id
      );
    },
    projectTaskDetail(project) {
      let filter = {
        selectedOption: {
          icon: 'mdi-inbox',
          text: 'project',
          value: 'project',
          operators: ['=', '!='],
        },
        selectedOperator: '=',
        selectedValues: [project],
        valueText: 'name',
      };
      this.$store.commit('tasks_add_advanced_filter', filter);
      this.$router.push({ name: 'tasks-gantt' });
    },
    openTaskDetail(task) {
      this.$router.push({ name: 'tasks-detail', params: { taskId: task.id } });
    },
  },
};
</script>

<style scoped lang="scss">
.ant-gantt-project-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    color: var(--v-primary-base);
    text-decoration: underline;
  }
}
</style>
